import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'peppershop',
    children: [

      {
        path: 'config',
        name: 'peppershop-config',
        meta: {
          title: computed(() => t('messages.shopConfig')),
          permissions: ['ADMIN'],
        },
        component: () => import('../PeppershopConfigIndex.vue'),
      },
    ],
  },
];
