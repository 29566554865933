export function createStorage(key: string, storage: Storage = sessionStorage) {
  const getItem = () => storage.getItem(key) || undefined;
  const deleteItem = () => storage.removeItem(key);
  const setItem = (value: string | undefined) => {
    if (!value) {
      deleteItem();

      return;
    }
    sessionStorage.setItem(key, value);
  };

  return {
    get: getItem,
    set: setItem,
    delete: deleteItem,
  };
}
