import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'ncr',
    children: [
      {
        path: 'lesson',
        redirect: (to) => ({
          name: 'ncr-lesson',
          params: { accountUuid: to.query?.uuid?.toString() },
          query: {},
        }),
      },
      {
        path: 'lesson/:accountUuid',
        name: 'ncr-lesson',
        props: true,
        component: () => import('../LessonIndex.vue'),
      },
    ],
  },
];
