import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { nextTick } from 'vue';

import { useGlobalI18n, setI18nLanguage } from '@abaninja/util-i18n';

export const i18n = useGlobalI18n();

async function loadLocaleMessages(locale: SupportedLocale) {
  // load locale messages with dynamic import
  const messages = await import(`../i18n/messages/${locale}.json`);
  const rules = await import(`../i18n/rules/${locale}.json`);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, { messages: messages.default, rules: rules.default });

  return nextTick();
}

export async function switchLanguage(locale: SupportedLocale) {
  await loadLocaleMessages(locale);

  setI18nLanguage(locale);
  setDefaultOptions({ locale: de });
}
