import { ref } from 'vue';

export const useUpdateEntity = <Args extends unknown[], Response>(
  handler: (...args: Args) => Promise<Response>,
) => {
  const isUpdating = ref(false);

  async function updateEntity(...args: Args) {
    isUpdating.value = true;

    try {
      return await handler(...args);
    } finally {
      isUpdating.value = false;
    }
  }

  return {
    isUpdating,
    updateEntity,
  };
};
