import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'inbox',
    children: [
      {
        path: '',
        name: 'inbox',
        component: () => import('../InboxIndex.vue'),
        meta: {
          title: computed(() => t('messages.notification', 2)),
        },
      },
      {
        path: ':uuid',
        name: 'inbox-item',
        component: () => import('../InboxItem.vue'),
        props: true,
        meta: {
          title: computed(() => t('messages.notification', 2)),
        },
      },
    ],
  },

];
