import { defineStore } from 'pinia';

import { useGetEntity, useUpdateEntity } from '@abaninja/util-store';

import { getMe, patchMe } from '../me';

export const useMeStore = defineStore('meStore', () => ({
  ...useGetEntity(getMe),
  ...useUpdateEntity(patchMe),
}));
