
import { ref } from 'vue';

export const useStatusCode = () => {
  const statusCode = ref<number>();

  function reset() {
    statusCode.value = undefined;
  }

  return {
    statusCode,
    reset,
  };
};
