<template>
  <v-system-bar v-if="entity?.availableUntil" class="text-body-2" color="primary" height="48" order="-1">
    <v-container class="text-center">
      {{ t('messages.accountWillBeDeletedAfter', { date: format(entity.availableUntil) }) }}
    </v-container>
  </v-system-bar>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import { useCurrentAccountStore } from '@abaninja/data-access-swiss21-account';
import { useDateTimeFormatter } from '@abaninja/util-formatters';

const currentAccountStore = useCurrentAccountStore();

const { entity } = storeToRefs(currentAccountStore);

const { format } = useDateTimeFormatter();

const { t } = useI18n();
</script>
