<template>
  <v-system-bar v-if="!hasVerifiedEmail" class="text-body-2" height="48" order="-1">
    <v-container class="text-center">
      {{ t('messages.needsEmailVerification') }}
      <v-btn class="ml-4" :href="verifyLink" size="small">{{ t('messages.verify') }}</v-btn>
    </v-container>
  </v-system-bar>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { VBtn, VContainer, VSystemBar } from 'vuetify/components';

import { useVerifiedEmail } from './composables/useVerifiedEmail';

const { t } = useI18n();

const { hasVerifiedEmail, verifyLink } = useVerifiedEmail();

</script>
