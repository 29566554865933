import { useScriptTag } from '@vueuse/core';
import { ref } from 'vue';

import type { Zendesk, ZendeskProviderProps } from '../@types';

export const useZendesk = ({ token, settings }: ZendeskProviderProps) => {
  const isLoading = ref(true);
  const hasLoaded = ref(false);
  const error = ref();

  const widgetUrl = new URL('/ekr/snippet.js', 'https://static.zdassets.com');

  widgetUrl.searchParams.append(
    'key',
    token,
  );

  const zendesk = window as unknown as Zendesk;

  if (token) {
    useScriptTag(widgetUrl.href, () => {
      zendesk.zESettings = settings;

      hasLoaded.value = true;
      isLoading.value = false;
    }, {
      attrs: {
        id: 'ze-snippet',
      },
    });
  }

  return {
    isLoading,
    error,
    zendesk: token ? zendesk : undefined,
  };
};
