import { mdiClose } from '@mdi/js';
import { createInjectionState } from '@vueuse/core';
import { isAxiosError, isCancel, type AxiosResponse } from 'axios';
import type { InvalidSubmissionHandler } from 'vee-validate';
import { toast } from 'vuetify-sonner';

import { useGlobalI18n } from '@abaninja/util-i18n';
import { objectKeys } from '@abaninja/util-object';

const { global: { t } } = useGlobalI18n();

export type Notification = {
  message: string;
  timeout: number;
  type: 'error' | 'info' | 'success' | 'warning';
  description?: string;
}

function showNotification(notification: Partial<Notification> & Pick<Notification, 'message'>) {
  toast(notification.message, {
    description: notification.description,
    cardProps: {
      color: notification.type ?? 'success',
      width: 'var(--width)',
      variant: 'tonal',
      border: false,
      elevation: 0,
      style: {
        background: 'rgba(var(--v-theme-background))',
      },
    },
    duration: notification.timeout ?? 5000,
    action: {
      buttonProps: {
        icon: mdiClose,
        size: 'x-small',
      },
    },
  });
}

function fromAxiosResponse(axiosResponse: AxiosResponse<Payload>) {
  if (axiosResponse.data.message) {
    showNotification({
      message: axiosResponse.data.message ?? axiosResponse.statusText,
    });
  }
}

function fromAxiosError(axiosError: unknown) {
  if (!isCancel(axiosError) && isAxiosError<Payload<ErrorPayload>>(axiosError)) {
    showNotification({
      message: axiosError.response?.data.message ?? axiosError.message,
      type: 'error',
    });
  }
}

const showVeeValidateInvalidSubmission: InvalidSubmissionHandler = (data) => {
  showNotification({
    message: t('messages.fieldIsNotCorrect', objectKeys(data.errors).length),
    type: 'error',
  });
};

const [useProvideNotificationContext, useNotificationContext] = createInjectionState(() => ({
  fromAxiosError,
  fromAxiosResponse,
  showNotification,
  showVeeValidateInvalidSubmission,
}));

export { useProvideNotificationContext };

export function useNotification() {
  const notification = useNotificationContext();

  if (!notification) {
    throw new Error('Please call `useProvideNotificationContext` on the appropriate parent component');
  }

  return notification;
}

