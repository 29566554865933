<template>
  <v-card>
    <template #title>
      {{ t('messages.youWillBeLoggedOutInX', { duration: formatDuration(remainingDuration) }) }}
    </template>
    <template #text>
      {{ t('messages.youWillBeLoggedOutDueToInactivity', { duration: formatDuration(inactivityIdleDuration) }) }}
    </template>
    <template #actions>
      <v-btn color="primary" @click="emits('reset')">{{ t('messages.stayLoggedIn') }}</v-btn>
    </template>
  </v-card>
</template>

<script lang="ts" setup>
import { type Duration, formatDuration, intervalToDuration } from 'date-fns';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { VBtn, VCard } from 'vuetify/components';

import { SHOW_INACTIVITY_MODAL_AFTER_IDLE_TIME } from '../constants';

interface Props {
  remainingDuration: Duration;
}

defineProps<Props>();

const emits = defineEmits<{
    'reset': [];
  }>();

const { t } = useI18n();

const inactivityIdleDuration = computed(() => intervalToDuration({
  start: 0,
  end: SHOW_INACTIVITY_MODAL_AFTER_IDLE_TIME,
}));

</script>
