import { defineStore } from 'pinia';

import { createAccessLevel } from '@abaninja/util-access-level';

import type { Account } from '../../@types';

export const useAccountRoleStore = defineStore('accountRole', () => {
  const accountRoleLevel: Account['accountRole'][] = [
    'EMPLOYEE',
    'ADMIN',
    'DC',
    'OWNER',
    'SUPPORT',
  ];

  const accessLevel = createAccessLevel<Account['accountRole']>(accountRoleLevel);

  return {
    ...accessLevel,
  };
});
