<template>
  <v-dialog :model-value="showInactivityModal" persistent>
    <inactivity-card v-if="!isTimeUp" :remaining-duration="remainingDuration" @reset="resetInactivityModal" />
    <login-card v-else />
  </v-dialog>
</template>

<script lang="ts" setup>
import { useIdle, useTimestamp } from '@vueuse/core';
import { intervalToDuration, isAfter } from 'date-fns';
import { computed, ref, watch } from 'vue';
import { VDialog } from 'vuetify/components';

import InactivityCard from './components/InactivityCard.vue';
import LoginCard from './components/LoginCard.vue';
import { INACTIVITY_MODAL_DISPLAY_TIME, SHOW_INACTIVITY_MODAL_AFTER_IDLE_TIME } from './constants';

const showInactivityModal = ref(false);
const inactivityModelShowTime = ref(new Date().getTime());

const { idle, reset } = useIdle(SHOW_INACTIVITY_MODAL_AFTER_IDLE_TIME);

const { timestamp, pause, resume } = useTimestamp({ controls: true, immediate: false });

const startDate = computed(() => new Date(timestamp.value));
const endDate = computed(() => new Date(inactivityModelShowTime.value + INACTIVITY_MODAL_DISPLAY_TIME));

const remainingDuration = computed(() => intervalToDuration({
  start: startDate.value,
  end: endDate.value,
}));

const isTimeUp = computed(() => isAfter(startDate.value, endDate.value));

function resetInactivityModal() {
  showInactivityModal.value = false;
  reset();
  pause();
}

watch(idle, (value) => {
  if (value && !showInactivityModal.value) {
    resume();
    showInactivityModal.value = true;
    inactivityModelShowTime.value = new Date().getTime();
  }
});
</script>
