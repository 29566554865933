import { defineStore } from 'pinia';

import { createAccessLevel } from '@abaninja/util-access-level';

import type { Me } from '../../@types';

const userRoleLevels: Me['userRole'][] = [
  'STANDARD',
  'DC',
  'TEACHER',
  'SUPPORT',
  'SUPPORT_AGENT',
  'SUPPORT_MANAGER',
  'SWISS21_ADMIN',
];

export const useUserRoleStore = defineStore('userRole', () => {
  const accessLevel = createAccessLevel<Me['userRole']>(userRoleLevels);

  return {
    ...accessLevel,
  };
});
