import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import { locationSessionStorageKey } from './keycloak';

export const createOAuthCbRoutes = (path: string, defaultRouteName: string): RouteRecordRaw[] => [{
  path,
  redirect: (): RouteLocationRaw => {
    const defaultRoute = { name: defaultRouteName, replace: true, hash: '' };
    const lastLocation = sessionStorage.getItem(locationSessionStorageKey);

    if (!lastLocation) {
      return defaultRoute;
    }

    const parsedLocation = JSON.parse(lastLocation) as Location;

    // prevent endless redirect
    if (parsedLocation.pathname.includes(path)) {
      return defaultRoute;
    }

    // redirect to requested location
    return {
      path: parsedLocation.pathname.replace('/v3', '').replace('/admin', ''), // v3 & admin fix
      replace: true,
      query: Object.fromEntries(new URLSearchParams(parsedLocation.search).entries()),
      hash: parsedLocation.hash,
    };
  },
}];
