import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export const initSentry = (app: App, router: Router) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',

    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],

    tracePropagationTargets: ['localhost', /^\//, import.meta.env.VITE_BASE_URL.replace('https://', '')],

    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.VITE_SENTRY_RELEASE_VERSION,
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',

    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
    autoSessionTracking: true,
  });
};
