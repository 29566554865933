<template>
  <v-card>
    <template #title>
      {{ t('messages.youHaveBeenLoggedOut') }}
    </template>
    <template #text>
      {{ t('messages.youHaveBeenLoggedOutDueToSecurityReasons') }}
    </template>
    <template #actions>
      <v-btn color="primary" @click="login">Login</v-btn>
    </template>
  </v-card>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { login } from '@abaninja/util-keycloak';

const { t } = useI18n();

</script>
