import { ref } from 'vue';

export const useCreateEntity = <Args extends unknown[], Response>(
  handler: (...args: Args) => Promise<Response>,
) => {
  const isCreating = ref(false);

  async function createEntity(...args: Args) {
    isCreating.value = true;

    try {
      return await handler(...args);
    } finally {
      isCreating.value = false;
    }
  }

  return {
    isCreating,
    createEntity,
  };
};
