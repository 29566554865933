import { createInjectionState } from '@vueuse/core';

import { useAppNavigation } from './AppNavigation.use';

const [provideAppNavigation, injectAppNavigation] = createInjectionState(useAppNavigation);

export { provideAppNavigation };

export { injectAppNavigation };

export function injectAppNavigationOrThrow() {
  const context = injectAppNavigation();

  if (context === null) {
    throw new Error(
      'Please call `provideAppNavigation` on the appropriate parent component',
    );
  }

  return context;
}
