import { shallowRef } from 'vue';

export const useMeta = <T>(initialMeta?: T) => {
  const meta = shallowRef<T | undefined>(initialMeta);

  function reset() {
    meta.value = initialMeta;
  }

  return {
    meta,
    reset,
  };
};
