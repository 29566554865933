import { ref } from 'vue';
import { useDisplay } from 'vuetify';

export const useAppNavigationIcon = () => {
  const { mdAndDown } = useDisplay();
  const isOpen = ref(!mdAndDown.value);

  function toggleNavigation() {
    isOpen.value = !isOpen.value;
  }

  return {
    isOpen,
    toggleNavigation,
    displayIcon: mdAndDown,
  };
};
