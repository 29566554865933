<template>
  <v-system-bar v-if="isNewVersionAvailable" class="text-body-2" height="48">
    <v-container class="text-center">
      {{ t('messages.newVersionAvailable') }}
      <v-btn class="ml-4" size="small" @click="reload">{{ t('messages.reload') }}</v-btn>
    </v-container>
  </v-system-bar>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import { useNewVersionAvailable } from './composables/useNewVersionAvailable';

const { t } = useI18n();

const { isNewVersionAvailable } = useNewVersionAvailable();

function reload() {
  // @ts-ignore almost every browser supports the reload with boolean attribute (MDN)
  location.reload(true);
}

</script>

