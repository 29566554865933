import { createI18n } from 'vue-i18n';

const DEFAULT_LOCALE = 'de';

const i18n = createI18n({ locale: DEFAULT_LOCALE, legacy: false });

export const useGlobalI18n = () => i18n;

export function setI18nLanguage(locale: string) {
  i18n.global.locale.value = locale;
}
