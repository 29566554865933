import { createApp } from 'vue';

import { createApiClient, createHttpClient } from '@abaninja/util-http-client';

import App from './App.vue';
import 'vuetify/styles';
import 'unfonts.css';
import './scss/base.scss';
import { initGtm } from './plugins/gtm';
import { i18n } from './plugins/i18n';
import { initKeycloak } from './plugins/keycloak';
import { pinia } from './plugins/pinia';
import { initSentry } from './plugins/sentry';
import { unhead } from './plugins/unhead';
import { createVeeValidate } from './plugins/vee-validate';
import { vuetify } from './plugins/vuetify';
import router from './router';

const app = createApp(App);

// needs to be outside of cb function
app.use(initGtm(router));

const createApplication = () => {
  createHttpClient({
    baseURL: import.meta.env.VITE_API_URL,
  });

  createApiClient({
    baseURL: import.meta.env.VITE_API_URL,
  });

  initSentry(app, router);

  // init plugins
  app.use(vuetify);
  app.use(i18n);
  app.use(pinia);
  app.use(router);
  app.use(unhead);

  createVeeValidate();

  app.mount('#root');
};

const maintenanceIgnore = sessionStorage.getItem('maintenanceIgnore') === 'true';
if (import.meta.env.VITE_FORCE_MAINTENANCE === 'true' && !maintenanceIgnore) {
  location.replace(new URL(import.meta.env.VITE_MAINTENANCE_WINDOW_PATH, import.meta.env.VITE_MAINTENANCE_WINDOW_URL).href);
}

initKeycloak(createApplication);
