import { type Ref, ref } from 'vue';

export const useItemMap = <Id, Value>() => {
  const items = ref(new Map()) as Ref<Map<Id, Value>>;

  function setItem(id: Id, value: Value) {
    items.value.set(id, value);
  }

  function getItem(id: Id) {
    return items.value.get(id);
  }

  function deleteItem(id: Id) {
    return items.value.delete(id);
  }

  return {
    items,
    deleteItem,
    getItem,
    setItem,
  };
};
