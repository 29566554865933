export const createCancellablePromise = <TResponse = unknown>(
  callable: (abortController: AbortController) => TResponse,
) => {
  const abortController = new AbortController();

  return {
    promise: () => callable(abortController),
    abortController,
    cancel: () => abortController.abort(),
  };
};

export type CancellablePromise<T = unknown> = ReturnType<typeof createCancellablePromise<T>>
