function getUrlSearchParams() {
  return new URLSearchParams(window.location.search);
}

export function getUuidFromRoute(): string | null {
  return getUrlSearchParams().get('uuid');
}

export function getAppInstanceUuidFromRoute(): string | null {
  return getUrlSearchParams().get('app_instance_uuid');
}

export function hasAccountUrlParamsSet() {
  return Boolean(getUuidFromRoute() ?? getAppInstanceUuidFromRoute());
}
