import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { createLoginUrl, keycloak } from '@abaninja/util-keycloak';

export const useVerifiedEmail = () => {
  const { locale } = useI18n();

  const hasVerifiedEmail = computed(() => keycloak?.tokenParsed?.email_verified);

  const verifyLink = computed(() => {
    const loginUrl = createLoginUrl();

    if (!loginUrl) {
      return undefined;
    }

    const url = new URL(loginUrl);

    url.searchParams.append('kc_action', 'VERIFY_EMAIL_APP_TRIGGERED');
    url.searchParams.append('kc_locale', locale.value);

    return url.href;
  });

  return {
    hasVerifiedEmail,
    verifyLink,
  };
};
