import { createInjectionState } from '@vueuse/core';

import { useAppNavigationIcon } from './AppNavigationIcon.use';

const [provideAppNavigationIcon, injectAppNavigationIcon] = createInjectionState(useAppNavigationIcon);

export { provideAppNavigationIcon };

export function injectAppNavigationIconOrThrow() {
  const context = injectAppNavigationIcon();

  if (!context) {
    throw new Error(
      'Please call `provideAppNavigationIcon` on the appropriate parent component',
    );
  }

  return context;
}
