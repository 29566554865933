import { type AxiosPromise } from 'axios';
import { type Ref, type ShallowRef, ref, shallowRef } from 'vue';

import { type CancellablePromise } from './createCancellablePromise';
import { useLoadable } from './useLoadable';
import { useMeta } from './useMeta';
import { useStatusCode } from './useStatusCode';

// eslint-disable-next-line max-lines-per-function
export const useGetEntity = <TArgs extends unknown[], TData, TMeta, Shallow extends boolean = true>(
  handler: (...args: TArgs) => CancellablePromise<AxiosPromise<Payload<TData, TMeta>>>,
  initialMeta?: TMeta,
  initialState?: TData,
  options?: {
    shallow: Shallow;
  },
) => {
  const { shallow = true } = options ?? {};

  const state = shallow ? shallowRef(initialState) : ref(initialState);

  function resetState() {
    state.value = initialState;
  }

  const { isLoading, hasLoaded, abortController, cancel, reset: resetLoadable } = useLoadable();
  const { meta, reset: resetMeta } = useMeta<TMeta>(initialMeta);
  const { statusCode, reset: resetStatusCode } = useStatusCode();

  async function getEntity(...args: TArgs) {
    cancel();

    const { abortController: controller, promise } = handler(...args);
    abortController.value = controller;

    try {
      isLoading.value = true;

      const response = await promise();
      state.value = response.data.data;
      meta.value = response.data.meta;
      statusCode.value = response.status;
      hasLoaded.value = true;
      isLoading.value = false;

      return response;
    } finally {
      isLoading.value = false;
    }
  }

  async function requireEntity(...args: TArgs) {
    if (!isLoading.value && !hasLoaded.value) {
      await getEntity(...args);
    }

    return true;
  }

  function reset() {
    resetLoadable();
    resetState();
    resetStatusCode();
    resetMeta();
  }

  return {
    isLoading,
    hasLoaded,
    entity: state as Shallow extends true ? ShallowRef<TData | undefined> : Ref<TData | undefined>,
    meta,
    statusCode,
    cancel,
    getEntity,
    requireEntity,
    reset,
  };
};
