import { type InternalAxiosRequestConfig } from 'axios';
import * as qs from 'qs';

import { keycloak } from '@abaninja/util-keycloak';

export const requestBearerTokenInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const newConfig = config;

  if (keycloak?.token) {
    newConfig.headers.Authorization = `Bearer ${keycloak.token}`;
  }

  return newConfig;
};

export const requestParamsSerializerInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (!config.method || !['GET', 'DELETE'].includes(config.method.toUpperCase())) {
    return config;
  }

  return {
    ...config,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  };
};

export const requestWebapiInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const accountUuid = sessionStorage.getItem('accountUuid');

  if (!accountUuid) {
    throw new Error('please provide a accountUuid');
  }

  return {
    ...config,
    url: `/webapi/accounts/${accountUuid}${config.url}`,
  };
};

export const requestWebapiSettingsInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const accountUuid = sessionStorage.getItem('accountUuid');

  if (!accountUuid) {
    throw new Error('please provide a accountUuid');
  }

  return {
    ...config,
    url: `/webapi/settings/accounts/${accountUuid}${config.url}`,
  };
};

export const requestApiInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const accountUuid = sessionStorage.getItem('accountUuid');

  if (!accountUuid) {
    throw new Error('please provide a accountUuid');
  }

  return {
    ...config,
    url: `/accounts/${accountUuid}${config.url}`,
  };
};
