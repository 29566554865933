import { computed } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'billing',
    name: 'billing',
    component: () => import('../BillingIndex.vue'),
    meta: {
      title: computed(() => t('messages.billing')),
      permissions: ['ADMIN'],
    },
  },
];
