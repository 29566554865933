import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { useAuthorizeStore } from '@abaninja/data-access-swiss21-deep-cloud';

// eslint-disable-next-line complexity
async function deepcloudConnectGuard(to: RouteLocationNormalized) {
  const code = typeof to.query.code === 'string' ? to.query.code : undefined;
  const state = typeof to.query.state === 'string' ? to.query.state : undefined;

  if (!code || !state) {
    return { name: 'dashboard' };
  }

  const callbackStore = localStorage.getItem(`kc-callback-${state}`);

  if (!callbackStore) {
    return { name: 'dashboard' };
  }

  const { pkceCodeVerifier } = JSON.parse(callbackStore);

  if (!pkceCodeVerifier) {
    return { name: 'dashboard' };
  }

  const { createEntity } = useAuthorizeStore();

  try {
    await createEntity({ pkceCodeVerifier, code });

    return true;
  } catch (error) {
    return { name: 'dashboard' };
  }
}

export const routes: RouteRecordRaw[] = [
  {
    path: 'deepcloud',
    children: [{
      path: 'connect',
      name: 'deepcloud-connect',
      component: () => import('../DeepcloudConnectIndex.vue'),

      beforeEnter: deepcloudConnectGuard,
    }, {
      path: 'connection/:uuid',
      name: 'deepcloud-connection',
      component: () => import('../DeepcloudConnectionIndex.vue'),
      props: true,
    }],
  },
];
