import { apiClient } from '@abaninja/util-http-client';
import { createCancellablePromise } from '@abaninja/util-store';

import type { Organization } from '../@types';

export function authorize(data: {code: string; pkceCodeVerifier: string}) {
  return apiClient.post<Payload>('/deep-cloud/v1/auth', data);
}

export function getOrganizations() {
  return createCancellablePromise(({ signal }) => apiClient.get<Payload<Organization[]>>(
    '/deep-cloud/v1/organizations', { signal },
  ));
}
