import type { Ref } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

export const useAppNavigation = (
  iconMap: Ref<Map<string, string> | undefined>,
  hiddenRouteMap: Ref<Map<string, boolean> | undefined>,
) => {
  function getIcon(key: string) {
    return iconMap.value?.get(key);
  }

  function getIconFromRoute(route: RouteRecordRaw) {
    return getIcon(route.name as string || route.path || '');
  }

  function isItemHidden(route: RouteRecordRaw) {
    return hiddenRouteMap.value?.get(route.name as string || route.path || '') ?? false;
  }

  return {
    getIcon,
    getIconFromRoute,
    isItemHidden,
  };
};
