import { isValid, toDate } from 'date-fns';

export const useDateTimeFormatter = () => {
  function format(value: Date | string, options?: Intl.DateTimeFormatOptions) {
    const date = toDate(value);

    if (!isValid(date)) {
      return '';
    }

    return new Intl.DateTimeFormat(
      navigator.language,
      options || { dateStyle: 'medium' },
    ).format(date);
  }

  return { format };
};
