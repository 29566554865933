export function createAccessLevel<T>(levels: Array<T>) {
  let currentLevel: T | undefined = undefined;

  function setCurrentLevel(level?: T) {
    currentLevel = level;
  }

  function needs(level: T) {
    if (!currentLevel) {
      return false;
    }

    return levels.indexOf(level) <= levels.indexOf(currentLevel);
  }

  function is(level: T) {
    return currentLevel === level;
  }

  return { needs, is, setCurrentLevel };
}
