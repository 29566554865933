import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';

import { type Account, useAccountsStore } from '@abaninja/data-access-swiss21-users';
import { getUuidFromRoute, hasAccountUrlParamsSet } from '@abaninja/util-route';
import { useGetEntity } from '@abaninja/util-store';

import { getAccount } from '../accounts';

let setCurrentAccountCalled = false;

const findAccountByUuid = (accountUuid: string) => (acc: Account) => acc.uuid === accountUuid;

// eslint-disable-next-line max-lines-per-function
export const useCurrentAccountStore = defineStore('currentAccount', () => {
  const accountUuidSessionStorage = useStorage<string | null>('accountUuid', null, sessionStorage);
  const accountUuidLocalStorage = useStorage<string | null>('accountUuid', null, localStorage);

  const accountsStore = useAccountsStore();

  const account = useGetEntity(getAccount);

  async function setCurrentAccount(accountUuid: string | null) {
    accountUuidSessionStorage.value = accountUuid;
    accountUuidLocalStorage.value = accountUuid;

    if (accountUuid) {
      try {
        await account.getEntity(accountUuid);
      } catch {
        // do nothing
      }
    }
  }

  function currentUserHasAccessToRequestedUuid(accountUuid: string | null) {
    if (!accountUuid) {
      return false;
    }

    return accountsStore.entities.value.some(findAccountByUuid(accountUuid));
  }

  function getUuidFromOwnerAccount() {
    return accountsStore.entities.value.find((acc) => acc.isOwner)?.uuid ?? null;
  }

  function getUuidFromAcceptedAccount() {
    return accountsStore.entities.value.find((acc) => acc.invitationStatus === 'ACCEPTED')?.uuid ?? null;
  }

  function getUuidFromFirstAccount() {
    return accountsStore.entities.value.at(0)?.uuid ?? null;
  }

  function getUuidFromBrowserStorage() {
    const uuid = accountUuidSessionStorage.value ?? accountUuidLocalStorage.value ?? null;

    return currentUserHasAccessToRequestedUuid(uuid) ? uuid : null;
  }

  async function setUuidFromUrlParams() {
    // ncr uuid fix
    // check if route is not a subpage of ncr
    if (window.location.pathname.includes('ncr')) {
      return false;
    }

    const uuid = getUuidFromRoute();
    await setCurrentAccount(uuid);

    return Boolean(uuid);
  }

  async function setUuidFromPersistedStorage() {
    const uuid = getUuidFromBrowserStorage()
      ?? getUuidFromOwnerAccount()
      ?? getUuidFromAcceptedAccount()
      ?? getUuidFromFirstAccount();

    await setCurrentAccount(uuid);

    return Boolean(uuid);
  }

  async function setDefaultAccount() {
    if (setCurrentAccountCalled) {
      return true;
    }
    setCurrentAccountCalled = true;

    // load all accounts
    await accountsStore.requireEntities();

    // Check if account Uuid is set in url
    if (hasAccountUrlParamsSet()) {
      return setUuidFromUrlParams();
    }

    return setUuidFromPersistedStorage();
  }

  function reloadCurrentAccount() {
    return setUuidFromPersistedStorage();
  }

  const accountRole = computed(() => {
    const accountUuid = account.entity.value?.uuid;
    if (!accountUuid) {
      return undefined;
    }

    return accountsStore.entities.value.find(findAccountByUuid(accountUuid))?.accountRole;
  });

  function switchAccount(uuid: string) {
    const redirectUrl = new URL('/', window.location.href);
    redirectUrl.searchParams.append('uuid', uuid);

    window.location.href = redirectUrl.href;
  }

  return {
    accountsStore,
    entity: account.entity,
    accountRole,
    setDefaultAccount,
    reloadCurrentAccount,
    switchAccount,
  };
});
