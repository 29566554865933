import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [

  {
    path: 'abaclik',
    name: 'abaclik',
    component: () => import('../AbaclikIndex.vue'),
  },
];
