import { httpClient } from '@abaninja/util-http-client';
import { createCancellablePromise } from '@abaninja/util-store';

import type { Me, MePatchData } from '../@types';

export function getMe() {
  return createCancellablePromise(({ signal }) => httpClient.get<Payload<Me>>('/users/v1/me', { signal }));
}

export function patchMe(data: MePatchData) {
  return httpClient.patch<Payload<Me>>('/users/v1/me', data);
}
