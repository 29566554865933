import { computed } from 'vue';

import { useItemMap } from '@abaninja/helper-item-map';

export const usePageLoaderContext = () => {
  const { items, setItem, deleteItem } = useItemMap<symbol, boolean>();

  const showLoader = computed(() => Boolean(items.value.size));

  return {
    showLoader,
    setItem,
    deleteItem,
  };
};
