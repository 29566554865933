import { createInjectionState } from '@vueuse/core';

import { useZendesk as useZendeskFn } from './zendesk.use';

const [provideZendesk, useInjectZendesk] = createInjectionState(useZendeskFn);

export { provideZendesk };

export function useZendesk() {
  const subscription = useInjectZendesk();

  if (!subscription) {
    throw new Error('Please call `provideZendesk` on the appropriate parent component');
  }

  return subscription;
}
