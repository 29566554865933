<template>
  <v-app>
    <new-version-system-toolbar />

    <email-verification-system-toolbar />

    <account-delete-system-toolbar />

    <router-view />

    <app-notifications />

    <confirmation-dialog />

    <session-expired-modal />

    <!-- Loader overlay -->
    <v-fade-transition>
      <div v-show="!hasLoaded" class="loader-wrapper">
        <loader />
      </div>
    </v-fade-transition>
  </v-app>
</template>

<script lang="ts" setup>
import * as Sentry from '@sentry/vue';
import { useHead } from '@unhead/vue';
import { syncRefs, watchImmediate } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useTheme } from 'vuetify';
import { VApp } from 'vuetify/components';

import { useMeStore } from '@abaninja/data-access-swiss21-users';
import { NewVersionSystemToolbar, useNewVersionAvailable } from '@abaninja/feature-new-version';
import { AppNotifications, useProvideNotificationContext } from '@abaninja/feature-notification';
import { SessionExpiredModal } from '@abaninja/feature-session-expired';
import { useThemeColorMode } from '@abaninja/feature-theme';
import { provideAppNavigationIcon } from '@abaninja/ui-app-navigation';
import { ConfirmationDialog, provideConfirmationDialog } from '@abaninja/ui-dialog';
import { EmailVerificationSystemToolbar } from '@abaninja/ui-email-verification';
import { Loader, providePageLoader } from '@abaninja/ui-loader';
import { useRouterState } from '@abaninja/util-router';
import { provideZendesk } from '@abaninja/util-zendesk';

import AccountDeleteSystemToolbar from './components/AccountDeleteSystemToolbar.vue';

const { hasLoaded } = storeToRefs(useRouterState());

// Set current theme
const theme = useTheme();
const themeColorMode = useThemeColorMode();
syncRefs(themeColorMode.currentTheme, theme.global.name);

// global providers
providePageLoader();
useProvideNotificationContext();
provideConfirmationDialog();

provideAppNavigationIcon();

useHead({
  titleTemplate: (title?: string) => !title ? 'Swiss21' : `${title} | Swiss21`,
});

const { zendesk } = provideZendesk({
  token: import.meta.env.VITE_ZENDESK_TOKEN ?? '',
  settings: {
    webWidget: {
      color: {
        theme: theme.current.value.colors.primary,
      },
      position: {
        horizontal: 'left',
        vertical: 'bottom',
      },
    },
  },
});

watch(theme.current, (value) => {
  zendesk?.zE('webWidget', 'updateSettings', {
    webWidget: {
      color: {
        theme: value.colors.primary,
      },
    },
  });
});

const { entity } = storeToRefs(useMeStore());

// zendesk
watchImmediate(entity, (value) => {
  if (!value || !zendesk || typeof zendesk.zE !== 'function') {
    return;
  }

  zendesk?.zE('webWidget', 'identify', {
    name: `${value.firstName} ${value.lastName}`,
    email: value.email,
  });
});

// sentry
watchImmediate(entity, (value) => {
  if (!value) {
    return;
  }

  Sentry.setUser({
    id: value.uuid,
  });
});

const { isMaintenance } = useNewVersionAvailable();

watch(isMaintenance, (value) => {
  if (value) {
    const maintenanceIgnore = sessionStorage.getItem('maintenanceIgnore') === 'true';

    if (!maintenanceIgnore) {
      location.replace(new URL(import.meta.env.VITE_MAINTENANCE_WINDOW_PATH, import.meta.env.VITE_MAINTENANCE_WINDOW_URL).href);
    }
  }
});

</script>

<style lang="scss">
@use './scss/settings';

:root{
  // --border-radius-root: #{settings.$border-radius-root};
  --spacer: #{settings.$spacer};
  // --bootable-transition: #{settings.$bootable-transition};
  // --grid-gutter: #{settings.$grid-gutter * 0.5};
}

</style>

<style lang="postcss" scoped>
.loader-wrapper{
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--v-theme-background));
  z-index: 2000;
}
</style>
