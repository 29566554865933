import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRouterState = defineStore('routerState', () => {
  const isLoading = ref(false);
  const hasLoaded = ref(false);

  return {
    isLoading,
    hasLoaded,
  };
});
