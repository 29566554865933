import axios from 'axios';

import { createCancellablePromise } from '@abaninja/util-store';

import type { Version } from './../@types/index';

function createBaseUrl() {
  const baseUrl = import.meta.env.VITE_NINJA_BASE_PATH;

  if (!baseUrl || baseUrl === '/') {
    return '';
  }

  return `/${baseUrl}`;
}

export function getVersion() {
  return createCancellablePromise(
    ({ signal }) => axios.get<Payload<Version>>(
      `${createBaseUrl()}/version.json`,
      {
        signal,
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      },
    ),
  );
}
