import type { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js';

import { createKeycloak, refreshToken, token } from '@abaninja/util-keycloak';

const keycloakConfig: KeycloakConfig = {
  url: import.meta.env.VITE_S21_IDP_URL,
  clientId: import.meta.env.VITE_S21_IDP_CLIENT_ID,
  realm: import.meta.env.VITE_S21_IDP_REALM,
};

const keycloakInitOptions: KeycloakInitOptions = {
  scope: import.meta.env.VITE_S21_IDP_SCOPE.replaceAll(',', ' '),
  checkLoginIframe: false,
  timeSkew: 0,
  enableLogging: true,
  responseMode: 'fragment',
  token: token.get(),
  refreshToken: refreshToken.get(),
};

const redirectUri = `${import.meta.env.VITE_BASE_URL}/oauthcb`;

const keycloakLoginOptions: KeycloakLoginOptions = {
  scope: import.meta.env.VITE_S21_IDP_SCOPE.replaceAll(',', ' '),
  redirectUri,
};

const keycloakLogoutOptions: KeycloakLogoutOptions = {
  redirectUri: import.meta.env.VITE_S21_IDP_LOGOUT_REDIRECT_URI,
};

export const initKeycloak = createKeycloak(keycloakConfig, keycloakInitOptions, keycloakLoginOptions, keycloakLogoutOptions);
