import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [

  {
    path: 'accounts',
    children: [
      {
        path: '',
        name: 'accounts',
        component: () => import('../AccountsIndex.vue'),
      },
      {
        path: 'account',
        name: 'create-account',
        meta: {
          userRoles: ['TEACHER'],
        },
        component: () => import('../AccountCreate.vue'),
      },
    ],
  },
];
