import { createSharedComposable, useIntervalFn } from '@vueuse/core';
import { ref } from 'vue';

import { useVersionStore } from '@abaninja/data-access-version';

const UPDATE_INTERVAL = 30_000;

export const useNewVersionAvailable = createSharedComposable(() => {
  const { getEntity, entity } = useVersionStore();

  const currentVersion = import.meta.env.VITE_SENTRY_RELEASE_VERSION;

  const isNewVersionAvailable = ref(false);

  const isMaintenance = ref(false);

  async function checkVersion() {
    try {
      const response = await getEntity();
      if (response.data.data.version !== currentVersion) {
        isNewVersionAvailable.value = true;
      } else {
        isNewVersionAvailable.value = false;
      }

      isMaintenance.value = response.data.data.maintenance;
    } catch {
      isNewVersionAvailable.value = true;
    }
  }

  const { pause, resume } = useIntervalFn(() => {
    checkVersion();
  }, UPDATE_INTERVAL, { immediateCallback: true });

  return {
    pause,
    resume,
    isNewVersionAvailable,
    entity,
    currentVersion,
    isMaintenance,
  };
});
