import { computed } from 'vue';
import { type RouteRecordRaw } from 'vue-router';

import { useGlobalI18n } from '@abaninja/util-i18n';

const { global: { t } } = useGlobalI18n();

export const routes: RouteRecordRaw[] = [
  {
    path: 'subscriptions',
    name: 'subscriptions',
    component: () => import('../SubscriptionIndex.vue'),
    meta: {
      title: computed(() => t('messages.subscription', 2)),
      permissions: ['ADMIN'],
    },
  },
];
