import type { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js';
import Keycloak from 'keycloak-js';

import { createStorage } from '@abaninja/util-storage';

export const token = createStorage('kc-token');

export const refreshToken = createStorage('kc-refreshToken');

export let keycloak: Keycloak | undefined;

const saveTokensToStorage = () => {
  token.set(keycloak?.token);
  refreshToken.set(keycloak?.refreshToken);
};

const deleteTokens = () => {
  token.delete();
  refreshToken.delete();
};

export const locationSessionStorageKey = 'requestedLocation';

let keycloakInterval: NodeJS.Timeout | null = null;
const removeKeycloakRefreshInterval = () => {
  if (keycloakInterval) {
    clearInterval(keycloakInterval);
  }
};

async function refreshCb() {
  try {
    const isTokenUpdated = await keycloak?.updateToken(70);
    if (isTokenUpdated) {
      // setToken
      saveTokensToStorage();
    }
  } catch (error) {
    console.error(error);
  }
}

const createKeycloakRefreshInterval = () => {
  removeKeycloakRefreshInterval();

  return setInterval(refreshCb, 3000);
};

const initKeycloak = (
  keycloakConfig: KeycloakConfig,
  keycloakInitOptions: KeycloakInitOptions,
  keycloakLoginOptions: KeycloakLoginOptions,
) => async (onIsAuthenticated: () => void) => {
  try {
    // persist current route
    if (!window.location.pathname.includes('oauthcb')) {
      sessionStorage.setItem(locationSessionStorageKey, JSON.stringify(window.location));
    }

    const authenticated = await keycloak?.init(keycloakInitOptions);

    if (!authenticated) {
      keycloak?.login(keycloakLoginOptions);
    }

    // setToken
    saveTokensToStorage();

    // create Application
    onIsAuthenticated();

    // set interval
    keycloakInterval = createKeycloakRefreshInterval();
  } catch (error) {
    deleteTokens();
    keycloak?.login(keycloakLoginOptions);
  }
};

let loginOptions: KeycloakLoginOptions;
let logoutOptions: KeycloakLogoutOptions;

export const createKeycloak = (
  keycloakConfig: KeycloakConfig,
  keycloakInitOptions: KeycloakInitOptions,
  keycloakLoginOptions: KeycloakLoginOptions,
  keycloakLogoutOptions: KeycloakLogoutOptions,
) => {
  keycloak = new Keycloak(keycloakConfig);
  loginOptions = keycloakLoginOptions;
  logoutOptions = keycloakLogoutOptions;

  return initKeycloak(keycloakConfig, keycloakInitOptions, keycloakLoginOptions);
};

export const logout = () => {
  deleteTokens();
  keycloak?.logout(logoutOptions);
};

export const login = () => {
  deleteTokens();
  keycloak?.login(loginOptions);
};

export const createLoginUrl = () => keycloak?.createLoginUrl(loginOptions);

